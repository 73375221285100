import { Card } from '@yleisradio/areena-types';
import { useCookie } from './useCookie';
import { isValidRegionalChannelId } from 'utils/channel';
import logger from 'services/logger';

export const useRegionalChannelCookie = (
  cardDeck: Card
): [channelId: string | null, (channelId: string) => void] => {
  const persistentStateKey = cardDeck.persistentState?.key;

  const [selectedRegionalChannel, setCookie] = useCookie(
    persistentStateKey && `region-${persistentStateKey}`
  );

  if (
    selectedRegionalChannel != null &&
    !isValidRegionalChannelId(selectedRegionalChannel, cardDeck)
  ) {
    logger.warn('Ignoring invalid regional channel cookie');
    return [null, setCookie];
  }

  return [selectedRegionalChannel, setCookie];
};
