import { Overlay } from '@yleisradio/areena-types';
import { apiToCssColor } from 'utils/ui-helpers';
import styles from './ImageOverlay.module.scss';

interface ImageOverlayProps {
  overlay: Overlay;
}
export const ImageOverlay: React.FunctionComponent<ImageOverlayProps> = ({
  overlay,
}) => {
  const overlayColor = apiToCssColor(overlay.backgroundColor);

  return (
    <div
      style={{
        backgroundColor: overlayColor,
      }}
      className={styles.imageOverlay}
    >
      <span>{overlay.formatted}</span>
    </div>
  );
};
