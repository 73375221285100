import { ListStyle } from '@yleisradio/areena-types';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import styles from './PlaceholderCard.module.scss';

function getAspectRatioClassName(
  listStyle: ListStyle | undefined
): string | undefined {
  switch (listStyle?.image) {
    case '1:1':
      return styles['aspectRatio_1_1'];
    case '16:9':
    default:
      return styles['aspectRatio_16_9'];
    case '9:13':
      return styles['aspectRatio_9_13'];
  }
}

interface PlaceholderCardProps {
  listStyle: ListStyle | undefined;
}

export const PlaceholderCard: FunctionComponent<PlaceholderCardProps> = ({
  listStyle,
}) => {
  const aspectRatioStyles =
    listStyle?.image === '1:1'
      ? styles.placeholderCardVerticalWithAspectRatio_1_1
      : styles.placeholderCardVertical;
  return (
    <div
      className={classNames(
        styles.placeholderCard,
        getAspectRatioClassName(listStyle),
        listStyle?.layout === 'vertical' && aspectRatioStyles
      )}
    />
  );
};
