import { Card } from '@yleisradio/areena-types';

const prefixToParentMap = {
  'radio-vega-': 'yle-radio-vega',
  'yle-radio-suomi-': 'yle-radio-suomi',
} as const;

export function getParentChannel(channelId: string): string | null {
  for (const [prefix, parentId] of Object.entries(prefixToParentMap)) {
    if (channelId.startsWith(prefix)) return parentId;
  }
  return null;
}

export function isValidRegionalChannelId(id: string, cardDeck: Card): boolean {
  if (!cardDeck.cards) return false;

  return cardDeck.cards.some((card): boolean => {
    const channelId = card.persistentState?.id;
    return !!channelId && channelId === id;
  });
}
