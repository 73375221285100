import { Control as ControlType } from '@yleisradio/areena-types';
import classNames from 'classnames';
import React from 'react';
import PlayIcon from 'assets/circlePlayIcon.svg';
import { useTranslation } from 'hooks/useTranslation';
import { isNavigator } from 'components/Controls/Control/typeGuards';
import { useControl } from 'components/Controls/Control/useControl';
import { Link } from 'components/Link';
import styles from './PlayButton.module.scss';

interface Props {
  control: ControlType;
  isOverlay?: boolean;
}

export const PlayButton: React.FunctionComponent<Props> = ({
  control: controlProp,
  isOverlay,
}) => {
  const { control, action, executingAction } = useControl({
    control: controlProp,
  });
  const t = useTranslation();

  if (!control) return null;

  const className = classNames(
    styles.playButton,
    isOverlay && styles.playButtonAsOverlay,
    executingAction && styles.playButtonDisabled
  );
  const label = control.title || t('play');
  const children = <PlayIcon className={styles.icon} />;
  const navigatorPointer = isNavigator(control)
    ? control.destination
    : undefined;
  const onClick = !executingAction ? action : undefined;

  if (navigatorPointer) {
    return (
      <Link
        aria-label={label}
        className={className}
        onClick={onClick}
        pointer={navigatorPointer}
      >
        {children}
      </Link>
    );
  }
  return (
    <button aria-label={label} className={className} onClick={onClick}>
      {children}
    </button>
  );
};
