import { Control as ControlType } from '@yleisradio/areena-types';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import CloseIcon from 'assets/close.svg';
import { OperationState } from 'components/Controls';
import { useControl } from 'components/Controls/Control/useControl';
import styles from './RemoveButton.module.scss';

interface Props {
  control: ControlType;
  hideCard: (() => void) | undefined;
}

export const RemoveButton: React.FunctionComponent<Props> = ({
  control: controlProp,
  hideCard,
}) => {
  const [deleteOperationState, setDeleteOperationState] =
    useState<OperationState>('initial');
  const { control, action, executingAction } = useControl({
    control: controlProp,
    setOperationState: (newState: OperationState) =>
      setDeleteOperationState(newState),
  });

  useEffect(() => {
    if (deleteOperationState === 'complete') {
      if (hideCard) {
        hideCard();
      } else {
        throw new Error('hideCard should be called but is undefined');
      }
    }
  });

  if (!control) return null;

  const className = classNames(
    styles.removeButton,
    executingAction && styles.removeButtonDisabled
  );

  const onClick = !executingAction ? action : undefined;

  return (
    <button aria-label={control.title} className={className} onClick={onClick}>
      <CloseIcon aria-hidden={true} className={styles.icon} />
    </button>
  );
};
