import { Card, CardResponse, Language } from '@yleisradio/areena-types';
import { useEffect } from 'react';
import useSWR from 'swr';
import { useProgressCacheInvalidator } from 'contexts/ProgressCacheInvalidatorContext';
import { useTunnusContext } from 'contexts/TunnusContext';
import { fetchPlaybackHistory } from 'services/areena-api/fetchers';
import { getCardProgressIds } from 'utils/card';
import { useUILanguage } from './useUILanguage';

interface HookReturn {
  readonly historyEntries: Card[];
}

type FetcherParameters = [
  'progress',
  cardIds: string[],
  language: Language,
  isAuthenticated: boolean,
];

type FetcherType = (args: FetcherParameters) => Promise<CardResponse | null>;

const fetcher: FetcherType = async ([, cardIds, language, isAuthenticated]) => {
  if (!isAuthenticated) {
    return null;
  }

  const response = await fetchPlaybackHistory(cardIds, language);

  return response;
};

export function useProgress(cardList: Card[]): HookReturn {
  const language = useUILanguage();
  const { isAuthenticated } = useTunnusContext();
  const cardsWithProgressLabel = getCardProgressIds(cardList) || [];
  const key: FetcherParameters = [
    'progress',
    cardsWithProgressLabel,
    language,
    isAuthenticated ?? false,
  ];

  const { data, mutate: invalidate } = useSWR(key, fetcher);

  const { addInvalidator, deleteInvalidator } = useProgressCacheInvalidator();
  useEffect(() => {
    addInvalidator(invalidate);
    return () => deleteInvalidator(invalidate);
  }, [invalidate, addInvalidator, deleteInvalidator]);

  return {
    historyEntries: data?.data || [],
  };
}
